import React, { useContext, useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

import AdminLayout from "./layouts/Admin/Admin";
import AuthLayout from "./layouts/Auth/Auth";
import AuthContextProvider, { AuthContext } from "./context/AuthContext";
import { ToastProvider } from "./context/ToastContext";
import CandidateContextProvider from "./context/CandidateContext";
import ExpertiseContext from "./context/ExpertiseContext";
import CompanyContextProvider from "./context/CompanyContext";
import LoadingContextProvider from "./context/LoadingContext";
import JobContextProvider from "./context/JobContext";
import EducationContext from "./context/EducationContext";
import FavoriteContextProvider from "./context/FavoriteContext";
import IndicatorContextProvider from "./context/IndicatorContext";
import AnnotationContextProvider from "./context/AnnotationContext";
import CoursesContextProvider from "./context/CoursesContext";
import CompetenceContextProvider from "./context/CompetenceContext";
import ChatGptContextProvider from "./context/ChatGptContext";
import CustomerContextProvider, { CustomerContext } from "./context/CustomerContext";
import OfficeContextProvider from "./context/OfficeContext";
import TaxTasksContextProvider, { TaxTasksContext } from "./context/TaxTasksContext";
import RoutineContextProvider from "./context/RoutineContext";
import UploadContextProvider, { UploadContext } from "./context/UploadContext";
import DownloadContextProvider, { DownloadContext } from "./context/DownloadContext";
import FilesContextProvider from "./context/FilesContext";
import RoutineTypeContextProvider from "./context/RoutineTypeContext";
import UserTypeContextProvider from "./context/UserTypeContext";
import { OfficeContext } from "../src/context/OfficeContext";
import useUser from "./context/hooks/useUser";

const rootElement =
  document.getElementById("root") ?? document.createElement("div");
const root = ReactDOM.createRoot(rootElement);

function CustomRoute() {
  const { authenticated, loadingButton, handleLogout, objUserTk } = useContext(AuthContext);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const { handleGetOfficeByOfficeId } = useContext(OfficeContext);

  useEffect(() => {
    if (objUserTk.OfficeId) {
      handleGetOfficeByOfficeId(objUserTk.OfficeId);
    }
  }, [objUserTk.OfficeId]);

  setInterval(() => {
    handleLogout();
  }, 60 * 60 * 1000);

  if (loadingButton) {
    return <Route>Carregando...</Route>;
  }

  // const configuration = {
  //   onUpdate: (registration: ServiceWorkerRegistration) => {
  //     if (registration && registration.waiting) {
  //       if (window.confirm('Uma nova versão do aplicativo está disponível. Deseja recarregar a página para aplicar as alterações?')) {
  //         registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  //         window.location.reload();
  //       }
  //       else {
  //         registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  //         window.location.reload();
  //       }
  //     }
  //   }
  // };

  const configuration = {
    onUpdate: (registration: ServiceWorkerRegistration) => {
      if (registration && registration.waiting) {
        setShowUpdateModal(true);
      }
    }
  };

  serviceWorkerRegistration.register(configuration);

  const handleUpdateConfirmation = () => {
    serviceWorkerRegistration.unregister();
    caches.keys().then(function (cacheNames) {
      cacheNames.forEach(function (cacheName) {
        caches.delete(cacheName);
      });
    });
    window.location.reload();
    handleLogout();
  };

  if (!authenticated || loadingButton) {
    return (
      <Routes>
        <Route path="/auth/*" element={<AuthLayout />} />;
        <Route path="*" element={<Navigate to="/auth/" replace />} />
      </Routes>
    );
  }

  return (
    <>
      <Routes>
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="*" element={<Navigate to="/admin/" replace />} />
      </Routes>
      <Modal isOpen={showUpdateModal}>
        <ModalBody>
          Uma nova versão do aplicativo está disponível. Deseja recarregar a página para aplicar as alterações?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpdateConfirmation}>OK</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

root.render(
  <BrowserRouter>
    <ToastProvider>
      <LoadingContextProvider>
        <AuthContextProvider>
          <OfficeContextProvider>
            <UserTypeContextProvider>
              <CustomerContextProvider>
                <RoutineTypeContextProvider>
                  <RoutineContextProvider>
                    <TaxTasksContextProvider>
                      <UploadContextProvider>
                        <DownloadContextProvider>
                          <FilesContextProvider>
                            <CustomRoute />
                          </FilesContextProvider>
                        </DownloadContextProvider>
                      </UploadContextProvider>
                    </TaxTasksContextProvider>
                  </RoutineContextProvider>
                </RoutineTypeContextProvider>
              </CustomerContextProvider>
            </UserTypeContextProvider>
          </OfficeContextProvider>
        </AuthContextProvider>
      </LoadingContextProvider>
    </ToastProvider>
  </BrowserRouter >
);