import React, { useContext, useEffect, useState } from 'react';
import { Container, Table, Button, Row, Col, Input, Modal, ModalHeader, ModalBody, Form, FormGroup, Label } from 'reactstrap';
import PaginationMaterial from "../../components/PaginationMaterial/PaginationMaterial";
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Header from '../../components/Headers/Header';
import AdminNavbar from '../../components/Navbars/AdminNavbar/AdminNavbar';
import { AuthContext } from '../../context/AuthContext';
import { CustomerContext } from '../../context/CustomerContext';
import { CustomerType, CreateCustomerType, UpdateCustomerType } from '../../context/hooks/useCustomer';

const Customer = () => {
    const { objUserTk } = useContext(AuthContext);
    const {
        getAllCustomersPaged,
        importCustomers,
        createCustomer,
        updateCustomer,
        deleteCustomer,
        listCustomer,
        pageCount,
        toggleCustomerStatus,
    } = useContext(CustomerContext);

    const [loading, setLoading] = useState(false);
    const [newCustomer, setNewCustomer] = useState<CreateCustomerType>({
        razaoSocial: '', nomeFantasia: '', document: '', documentType: 0, telefone: '', emailAcesso: '', resend: false, photo: '', workerDownloadFilePath: '', officeId: objUserTk.OfficeId
    });
    const [editingCustomer, setEditingCustomer] = useState<UpdateCustomerType | null>(null);
    const [dataSearch, setDataSearch] = useState({ name: "" });
    const [currentPage, setCurrentPage] = useState(1);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [importModalOpen, setImportModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImagePreview(reader.result as string);
                setNewCustomer((prev) => ({ ...prev, photo: reader.result as string }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageChangeEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImagePreview(reader.result as string);
                setEditingCustomer((prevCustomer) => ({
                    ...prevCustomer!,
                    photo: reader.result as string,
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        setLoading(true);
        getAllCustomersPaged(1, "", objUserTk.OfficeId)
            .then(data => {
                setLoading(false);
            })
            .catch(error => {
                console.error("Erro ao buscar clientes:", error);
                setLoading(false);
            });
    }, []);

    const onChangePaginationCommon = async (e: any, value: any) => {
        await getAllCustomersPaged(value, dataSearch.name, objUserTk.OfficeId);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDataSearch({ ...dataSearch, name: e.target.value });
    };

    const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        getAllCustomersPaged(1, dataSearch.name, objUserTk.OfficeId)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    const handleCreateInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setNewCustomer(prevCustomer => ({
                ...prevCustomer,
                [name]: checked,
            }));
        } else {
            setNewCustomer(prevCustomer => ({
                ...prevCustomer,
                [name]: value,
            }));
        }
    };

    const handleCreateCustomer = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        createCustomer(newCustomer)
            .then(createdCustomer => {
                if (createdCustomer) {
                    setCreateModalOpen(false);
                    setNewCustomer({
                        razaoSocial: '', nomeFantasia: '', document: '', documentType: 0, telefone: '', emailAcesso: '', resend: false, photo: '', workerDownloadFilePath: '', officeId: objUserTk.OfficeId
                    });
                    getAllCustomersPaged(1, "", objUserTk.OfficeId);
                } else {
                    console.error("Erro ao criar cliente.");
                }
            })
            .catch(error => {
                console.error("Erro ao criar cliente:", error);
            });
    };

    const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;;
        setEditingCustomer(prevCustomer => ({
            ...prevCustomer!,
            [name]: type === 'checkbox' ? checked : value,
        }));
    }

    const handleEditCustomer = (customer: CustomerType) => {
        const updateData: UpdateCustomerType = {
            id: customer.id,
            razaoSocial: customer.razaoSocial,
            nomeFantasia: customer.nomeFantasia,
            document: customer.document,
            documentType: customer.documentType,
            telefone: customer.telefone,
            emailAcesso: customer.emailAcesso,
            resend: customer.resend,
            workerDownloadFilePath: customer.workerDownloadFilePath || '',
            photo: customer.photo || '',
        };
        setEditingCustomer(updateData);
        setImagePreview(customer.photo || null);
        setEditModalOpen(true);
    };

    const handleEditSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (editingCustomer) {
            updateCustomer(editingCustomer)
                .then(updatedCustomer => {
                    if (updatedCustomer) {
                        setEditModalOpen(false);
                        getAllCustomersPaged(1, "", objUserTk.OfficeId);
                    } else {
                        console.error("Erro ao atualizar cliente.");
                    }
                })
                .catch(error => {
                    console.error("Erro ao atualizar cliente:", error);
                });
        }
    };

    const handleDeleteCustomer = (customerId: number) => {
        if (window.confirm("Tem certeza que deseja excluir este cliente?")) {
            deleteCustomer(customerId)
                .then(() => {
                    console.log("Cliente excluído com sucesso");
                    setTimeout(() => {
                        getAllCustomersPaged(1, "", objUserTk.OfficeId);
                    }, 500);
                })
                .catch(error => {
                    console.error("Erro ao excluir cliente:", error);
                });
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleImportSubmit = async () => {
        if (selectedFile) {
            await importCustomers(selectedFile, objUserTk.OfficeId);
            getAllCustomersPaged(1, "", objUserTk.OfficeId);
            setImportModalOpen(false);
        } else {
            alert("Por favor, selecione um arquivo.");
        }
    };

    const toggleStatus = async (customerId: number) => {
        await toggleCustomerStatus(customerId);
        await getAllCustomersPaged(1, "", objUserTk.OfficeId);
    };

    return (
        <>
            <LoadingSpinner />
            <Header />
            <AdminNavbar
                brandText={"Gerenciar Clientes"}
                search={false}
                titleSearch={""}
            />

            <Container className="mt-5">
                <Row>
                    <Col xs="6">
                        <form className="d-flex" onSubmit={handleSearchSubmit}>
                            <Input
                                className="form-control-alternative"
                                type="text"
                                name="search"
                                id="search"
                                placeholder="Buscar por nome"
                                value={dataSearch.name}
                                onChange={handleSearchChange}
                                style={{ width: "75%" }}
                            />
                            <Button style={{ color: "#FFF", backgroundColor: "#585858" }} type="submit" size="m">Buscar</Button>
                        </form>
                    </Col>
                    <div className="ml-auto d-flex justify-content-end" style={{ marginRight: '10px' }}>
                        <Button
                            style={{ color: "#FFF", backgroundColor: "#585858" }}
                            size="m"
                            onClick={() => setCreateModalOpen(true)}
                        >
                            Adicionar cliente
                        </Button>
                        <Button
                            style={{ color: "#FFF", backgroundColor: "#17a2b8", marginLeft: "10px" }}
                            size="m"
                            onClick={() => setImportModalOpen(true)}
                        >
                            Importar clientes
                        </Button>
                    </div>
                </Row>

                <Row className="mt-4">
                    <Col>
                        <Table hover responsive className="table-striped">
                            <thead>
                                <tr>
                                    <th>Foto</th>
                                    <th>Razão Social</th>
                                    <th>Nome Fantasia</th>
                                    <th>Documento</th>
                                    <th>Telefone</th>
                                    <th>Email Acesso</th>
                                    <th>Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listCustomer.map((customer: CustomerType, index: number) => (
                                    <tr key={index}>
                                        <td><img src={customer.photo || 'https://via.placeholder.com/50'} alt="Foto" style={{ width: '50px', height: '50px', borderRadius: '50%' }} /></td>
                                        <td>{customer.razaoSocial}</td>
                                        <td>{customer.nomeFantasia}</td>
                                        <td>{customer.document}</td>
                                        <td>{customer.telefone}</td>
                                        <td>{customer.emailAcesso}</td>
                                        <td>
                                            <Button
                                                style={{
                                                    backgroundColor: customer.isActive ?? true ? "#28a745" : "#6c757d",
                                                    color: "white",
                                                    border: "none",
                                                }}
                                                onClick={() => toggleStatus(customer.id)}
                                            >
                                                {customer.isActive ?? true ? "Ativo" : "Inativo"}
                                            </Button>
                                            <Button onClick={() => handleEditCustomer(customer)}><i className="fa-solid fa-pen-to-square"></i></Button>
                                            <Button onClick={() => handleDeleteCustomer(customer.id)} color="danger"><i className="fa-solid fa-trash"></i></Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-end mt-3">
                            {pageCount && (
                                <div className="d-flex justify-content-center mt-4">
                                    <PaginationMaterial
                                        onChange={(e: any, value: any) => onChangePaginationCommon(e, value)}
                                        count={pageCount}
                                    />
                                </div>
                            )}
                            {listCustomer.length === 0 && (
                                <div style={{ color: "red" }}>NÃO FOI ENCONTRADO NENHUM REGISTRO</div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>

            <Modal isOpen={createModalOpen} toggle={() => {
                setCreateModalOpen(!createModalOpen);
                setNewCustomer({
                    razaoSocial: '', nomeFantasia: '', document: '', documentType: 0, telefone: '', emailAcesso: '', resend: false, photo: '', workerDownloadFilePath: '', officeId: objUserTk.OfficeId
                });
                setImagePreview(null);
            }} backdrop="static" size="lg">
                <ModalHeader toggle={() => setCreateModalOpen(!createModalOpen)}>Adicionar novo cliente</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleCreateCustomer}>
                        <FormGroup>
                            <Label className="form-control-label">Foto</Label>
                            <Input type="file" name="photo" id="photo" onChange={handleImageChange} />
                            {imagePreview && (
                                <div className="image-preview mt-3">
                                    <img src={imagePreview} alt="Preview" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                                </div>
                            )}
                        </FormGroup>

                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Label className="form-control-label" htmlFor="input-razaoSocial">Razão Social</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="text"
                                        name="razaoSocial"
                                        id="razaoSocial"
                                        value={newCustomer.razaoSocial}
                                        placeholder="Razão Social"
                                        onChange={handleCreateInputChange}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label className="form-control-label" htmlFor="input-nomeFantasia">Nome Fantasia</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="text"
                                        name="nomeFantasia"
                                        id="nomeFantasia"
                                        value={newCustomer.nomeFantasia}
                                        placeholder="Nome Fantasia"
                                        onChange={handleCreateInputChange}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Label className="form-control-label" htmlFor="documentType">Tipo de Documento</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="select"
                                        name="documentType"
                                        id="documentType"
                                        value={newCustomer.documentType}
                                        onChange={e => {
                                            const selectedType = parseInt(e.target.value);
                                            setNewCustomer(prevCustomer => ({
                                                ...prevCustomer,
                                                documentType: selectedType,
                                                document: ''
                                            }));
                                        }}
                                    >
                                        <option value={0} disabled>Selecione um tipo de documento</option>
                                        <option value={1}>CNPJ</option>
                                        <option value={2}>CPF</option>
                                        <option value={3}>Produtor Rural</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label className="form-control-label" htmlFor="input-document">Documento</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="text"
                                        name="document"
                                        id="input-document"
                                        value={newCustomer.document}
                                        placeholder="Informe o documento"
                                        onChange={handleCreateInputChange}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label className="form-control-label" htmlFor="input-telefone">Telefone</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="text"
                                        name="telefone"
                                        id="telefone"
                                        value={newCustomer.telefone}
                                        placeholder="Telefone"
                                        onChange={handleCreateInputChange}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <Label className="form-control-label" htmlFor="input-emailAcesso">Email Acesso</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="email"
                                        name="emailAcesso"
                                        id="emailAcesso"
                                        value={newCustomer.emailAcesso}
                                        placeholder="Email de Acesso"
                                        onChange={handleCreateInputChange}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <Label className="form-control-label" htmlFor="input-workerDownloadFilePath">Caminho para download automático</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="text"
                                        name="workerDownloadFilePath"
                                        id="workerDownloadFilePath"
                                        value={newCustomer.workerDownloadFilePath}
                                        placeholder="Caminho para download automático"
                                        onChange={handleCreateInputChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <Label className="form-control-label" htmlFor="input-resend">Permitir reenviar arquivos?</Label>
                                    <Input
                                        className="form-control-alternative ml-3"
                                        type="checkbox"
                                        name="resend"
                                        id="resend"
                                        checked={newCustomer.resend}
                                        onChange={handleCreateInputChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <div className="d-flex justify-content-end mt-4">
                            <Button style={{ color: "#FFF", backgroundColor: "#585858" }} type="submit" size="m">Criar cliente</Button>
                            <Button style={{ color: "#FFF", backgroundColor: "#d9534f" }} className="ml-2" onClick={() => {
                                setCreateModalOpen(false);
                                setNewCustomer({
                                    razaoSocial: '', nomeFantasia: '', document: '', documentType: 0, telefone: '', emailAcesso: '', resend: false, photo: '', workerDownloadFilePath: '', officeId: objUserTk.OfficeId
                                });
                                setImagePreview(null);
                            }} size="m">Cancelar</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>

            <Modal isOpen={editModalOpen} toggle={() => setEditModalOpen(!editModalOpen)} backdrop="static" size="lg">
                <ModalHeader toggle={() => setEditModalOpen(!editModalOpen)}>Alterar informações do cliente</ModalHeader>
                <ModalBody>
                    {editingCustomer && (
                        <Form onSubmit={handleEditSubmit}>
                            <FormGroup>
                                <Label className="form-control-label">Foto</Label>
                                <Input type="file" name="photo" id="photo" onChange={handleImageChangeEdit} />
                                {imagePreview && (
                                    <div className="image-preview mt-3">
                                        <img src={imagePreview} alt="Preview" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                                    </div>
                                )}
                            </FormGroup>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label className="form-control-label" htmlFor="input-razaoSocial">Razão Social</Label>
                                        <Input
                                            className="form-control-alternative"
                                            type="text"
                                            name="razaoSocial"
                                            id="razaoSocial"
                                            value={editingCustomer?.razaoSocial || ''}
                                            placeholder="Razão Social"
                                            onChange={handleEditInputChange}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label className="form-control-label" htmlFor="input-nomeFantasia">Nome Fantasia</Label>
                                        <Input
                                            className="form-control-alternative"
                                            type="text"
                                            name="nomeFantasia"
                                            id="nomeFantasia"
                                            value={editingCustomer?.nomeFantasia || ''}
                                            placeholder="Nome Fantasia"
                                            onChange={handleEditInputChange}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label className="form-control-label" htmlFor="documentType">Tipo de Documento</Label>
                                        <Input
                                            className="form-control-alternative"
                                            type="select"
                                            name="documentType"
                                            id="documentType"
                                            value={editingCustomer?.documentType || 0}
                                            onChange={e => {
                                                const selectedType = parseInt(e.target.value);
                                                setEditingCustomer(prevCustomer => {
                                                    if (!prevCustomer) return null;
                                                    return {
                                                        ...prevCustomer,
                                                        documentType: selectedType,
                                                        document: ''
                                                    };
                                                });
                                            }}
                                        >
                                            <option value={0} disabled>Selecione um tipo de documento</option>
                                            <option value={1}>CNPJ</option>
                                            <option value={2}>CPF</option>
                                            <option value={3}>Produtor Rural</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label className="form-control-label" htmlFor="input-document">Documento</Label>
                                        <Input
                                            className="form-control-alternative"
                                            type="text"
                                            name="document"
                                            id="input-document"
                                            value={editingCustomer?.document || ''}
                                            placeholder="Informe o documento"
                                            onChange={handleEditInputChange}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label className="form-control-label" htmlFor="input-telefone">Telefone</Label>
                                        <Input
                                            className="form-control-alternative"
                                            type="text"
                                            name="telefone"
                                            id="telefone"
                                            value={editingCustomer?.telefone || ''}
                                            placeholder="Telefone"
                                            onChange={handleEditInputChange}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <Label className="form-control-label" htmlFor="input-emailAcesso">Email Acesso</Label>
                                        <Input
                                            className="form-control-alternative"
                                            type="email"
                                            name="emailAcesso"
                                            id="emailAcesso"
                                            value={editingCustomer?.emailAcesso || ''}
                                            placeholder="Email Acesso"
                                            onChange={handleEditInputChange}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <Label className="form-control-label" htmlFor="input-workerDownloadFilePath">Caminho para download automático</Label>
                                        <Input
                                            className="form-control-alternative"
                                            type="text"
                                            name="workerDownloadFilePath"
                                            id="workerDownloadFilePath"
                                            value={editingCustomer?.workerDownloadFilePath || ''}
                                            placeholder="Caminho para download automático"
                                            onChange={handleEditInputChange}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <Label className="form-control-label" htmlFor="input-resend">Permitir reenviar arquivos?</Label>
                                        <Input
                                            className="form-control-alternative ml-3"
                                            type="checkbox"
                                            name="resend"
                                            id="resend"
                                            checked={editingCustomer?.resend || false}
                                            onChange={handleEditInputChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className="d-flex justify-content-end">
                                <Button style={{ color: "#FFF", backgroundColor: "#585858" }} type="submit" size="m">Salvar alterações</Button>
                                <Button style={{ color: "#FFF", backgroundColor: "#d9534f" }} className="ml-2" onClick={() => {
                                    setEditModalOpen(false);
                                    setImagePreview(null);
                                }} size="m">Cancelar</Button>
                            </div>
                        </Form>
                    )}
                </ModalBody>
            </Modal>

            <Modal isOpen={importModalOpen} toggle={() => setImportModalOpen(!importModalOpen)} backdrop="static" size="lg">
                <ModalHeader toggle={() => setImportModalOpen(!importModalOpen)}>Importar clientes via arquivo</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label className="form-control-label" htmlFor="file-upload">Selecionar arquivo (.csv ou .xlsx)</Label>
                            <Input type="file" name="file" id="file-upload" accept=".csv, .xlsx" onChange={handleFileChange} />
                        </FormGroup>
                        <div className="d-flex justify-content-end mt-4">
                            <Button style={{ color: "#FFF", backgroundColor: "#17a2b8" }} onClick={handleImportSubmit} size="m">Enviar arquivo</Button>
                            <Button style={{ color: "#FFF", backgroundColor: "#d9534f" }} className="ml-2" onClick={() => setImportModalOpen(false)} size="m">Cancelar</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>

        </>
    );
};

export default Customer;