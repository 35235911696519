import { ReactNode, createContext } from "react";
import useTaxTasks, { TaxTasksType, UpdateTaxTasksType } from "./hooks/useTaxTasks";

interface TaxTasksContextProviderProps {
    children: ReactNode;
}

export interface TaxTasksContextData {
    getAllTaxTaskPaged: (
        pageNumber: number,
        routineId?: number,
        officeId?: number,
        status?: number,
        customerName?: string,
        routineDescription?: string,
        startDate?: string,
        endDate?: string,
        tag?: string,
    ) => Promise<TaxTasksType[] | null>;
    updateTaxTasks: (updateTaxTasksType: UpdateTaxTasksType) => Promise<UpdateTaxTasksType | null>;
    exportTaxTasks: (
        pageNumber: number,
        routineId?: number,
        officeId?: number,
        status?: number,
        customerName?: string,
        routineDescription?: string,
        startDate?: string,
        endDate?: string,
        tag?: string,
    ) => Promise<void | null>;
    setTaxTasks: any;
    TaxTasks: TaxTasksType;
    listTaxTasks: TaxTasksType[];
    initialListTaxTasks: TaxTasksType[];
    setListTaxTasks: any;
    setPageCount: any;
    pageCount: number;
    setTaskPageCount: any;
    taskPageCount: number;
}

const initialState: TaxTasksContextData = {
    getAllTaxTaskPaged: async (
        pageNumber: number,
        routineId?: number,
        officeId?: number,
        status?: number,
        customerName?: string,
        routineDescription?: string,
        startDate?: string,
        endDate?: string,
        tag?: string,
    ) => {
        return Promise.resolve(null);
    },
    updateTaxTasks: (updateTaxTasksType: UpdateTaxTasksType) => {
        return Promise.resolve(null);
    },
    exportTaxTasks: async (
        pageNumber: number,
        routineId?: number,
        officeId?: number,
        status?: number,
        customerName?: string,
        routineDescription?: string,
        startDate?: string,
        endDate?: string,
        tag?: string,
    ) => {
        return Promise.resolve(null);
    },
    setTaxTasks: () => { },
    TaxTasks: {
        id: 0,
        observation: "",
        shippingDate: "",
        submissionDate: "",
        workerDownloadDate: "",
        status: "",
        hasDocument: false,
        routine: {
            id: 0,
            description: "",
            initialDate: "",
            tags: [],
            customer: {
                razaoSocial: "",
                cnpj: "",
                emailCobranca: "",
            },
        },
    },
    listTaxTasks: [],
    initialListTaxTasks: [],
    setListTaxTasks: () => {},
    setPageCount: () => {},
    pageCount: 0,
    setTaskPageCount: () => {},
    taskPageCount: 0,
};

export const TaxTasksContext =
    createContext<TaxTasksContextData>(initialState);

export default function TaxTasksContextProvider({
    children,
}: TaxTasksContextProviderProps) {
    const {
        getAllTaxTaskPaged,
        updateTaxTasks,
        exportTaxTasks,
        setTaxTasks,
        TaxTasks,
        listTaxTasks,
        initialListTaxTasks,
        setListTaxTasks,
        setPageCount,
        pageCount,
        setTaskPageCount,
        taskPageCount,
    } = useTaxTasks();

    return (
        <TaxTasksContext.Provider
            value={{
                getAllTaxTaskPaged,
                updateTaxTasks,
                exportTaxTasks,
                setTaxTasks,
                TaxTasks,
                listTaxTasks,
                initialListTaxTasks,
                setListTaxTasks,
                setPageCount,
                pageCount,
                setTaskPageCount,
                taskPageCount,
            }}
        >
            {children}
        </TaxTasksContext.Provider>
    );
}