import React, { useState, useEffect, useContext } from 'react';
import { Card, CardBody, Col, Container, Row, Table, Button } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext';
import useTaxTasks, { TaxTasksType } from '../../context/hooks/useTaxTasks';
import axios from 'axios';
import './Dashboard.css';

const Dashboard = () => {
  const { objUserTk } = useContext(AuthContext);
  const { getAllTaxTaskPaged, listTaxTasks } = useTaxTasks(); // Função para pegar tarefas
  const [routines, setRoutines] = useState([]); // Estado para as rotinas
  const [completedTasks, setCompletedTasks] = useState(0);
  const [pendingTasks, setPendingTasks] = useState(0);
  const [overdueTasks, setOverdueTasks] = useState(0);
  const [overdueRoutines, setOverdueRoutines] = useState([]);

  useEffect(() => {
    fetchTasks(); // Busca as tarefas
    fetchRoutines(); // Busca as rotinas
  }, []);

  const fetchTasks = async () => {
    try {
      const tasks = await getAllTaxTaskPaged(1, 0, objUserTk.UserId); // Chama o endpoint de tarefas
      if (tasks) {
        calculateTaskStats(tasks);
      }
    } catch (error) {
      toast.error('Erro ao buscar tarefas.');
    }
  };

  const fetchRoutines = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/Routine/getAllRoutinePaged`, {
        params: {
          UserOfficeId: objUserTk.UserId,
          PageNumber: 1,
          PageSize: 10,
        },
        headers: { Authorization: `Bearer ${objUserTk.token}` },
      });
      const routinesData = response.data.result;
      setRoutines(routinesData); // Atualiza o estado das rotinas
      filterOverdueRoutines(routinesData);
    } catch (error) {
      toast.error('Erro ao buscar rotinas.');
    }
  };

  const calculateTaskStats = (taskData: TaxTasksType[]) => {
    const completed = taskData.filter(task => task.status === 'completed').length;
    const pending = taskData.filter(task => task.status === 'pending').length;
    const overdue = taskData.filter(task => task.status === 'overdue').length;

    setCompletedTasks(completed);
    setPendingTasks(pending);
    setOverdueTasks(overdue);
  };

  const filterOverdueRoutines = (routineData) => {
    const overdueRoutines = routineData.filter(routine => routine.status === 'EmAtraso');
    setOverdueRoutines(overdueRoutines);
  };

  const taskDataByWeek = {
    labels: ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'],
    datasets: [
      {
        label: 'Tarefas por Semana',
        data: listTaxTasks.map(task => task.id), // Exemplo fictício
        backgroundColor: 'rgba(88,88,88,1)',
        borderWidth: 1,
      }
    ]
  };

  return (
    <Container className="dashboard-container">
      {/* Seção de Tarefas */}
      <Row className="mb-4">
        <Col lg="4">
          <Card className="task-card">
            <CardBody>
              <h5 className="card-title">Tarefas Concluídas</h5>
              <h3 className="task-count">{completedTasks}</h3>
            </CardBody>
          </Card>
        </Col>
        <Col lg="4">
          <Card className="task-card">
            <CardBody>
              <h5 className="card-title">Tarefas Pendentes</h5>
              <h3 className="task-count">{pendingTasks}</h3>
            </CardBody>
          </Card>
        </Col>
        <Col lg="4">
          <Card className="task-card">
            <CardBody>
              <h5 className="card-title">Tarefas Atrasadas</h5>
              <h3 className="task-count">{overdueTasks}</h3>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* Gráfico de Tarefas por Semana */}
      <Row>
        <Col lg="8">
          <h4 className="section-title text-center">Distribuição de Tarefas por Semana</h4>
          <Bar data={taskDataByWeek} />
        </Col>

        <Col lg="4">
          <h4 className="section-title text-center">Lista de Tarefas</h4>
          <div className="task-list">
            <Table hover responsive>
              <thead className="thead-dark">
                <tr>
                  <th>ID</th>
                  <th>Descrição</th>
                </tr>
              </thead>
              <tbody>
                {listTaxTasks.map((task: TaxTasksType) => (
                  <tr key={task.id}>
                    <td>{task.id}</td>
                    <td>{task.routine.description}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>

      {/* Seção de Rotinas */}
      <Row className="mt-5">
        <Col lg="12">
          <h4 className="section-title text-center">Rotinas</h4>
          <Table hover responsive className="table-striped">
            <thead className="thead-dark">
              <tr>
                <th>Cliente</th>
                <th>Descrição</th>
                <th>Data Inicial</th>
                <th>Data Final</th>
                <th>Frequência</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {routines.map((routine) => (
                <tr key={routine.id}>
                  <td>{routine.customer.nomeFantasia || routine.customer.razaoSocial}</td>
                  <td>{routine.description}</td>
                  <td>{routine.initialDate}</td>
                  <td>{routine.finalDate || 'Não Definida'}</td>
                  <td>{routine.frequency}</td>
                  <td>
                    <span className="status-overdue">{routine.status === 'EmAtraso' ? 'Em Atraso' : 'Ativa'}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
