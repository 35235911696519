import React, { useContext, useEffect, useState } from 'react';
import { Container, Table, Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import PaginationMaterial from "../../components/PaginationMaterial/PaginationMaterial";
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Header from '../../components/Headers/Header';
import AdminNavbar from '../../components/Navbars/AdminNavbar/AdminNavbar';
import './CustomerRoutine.css';
import { AuthContext } from '../../context/AuthContext';
import { RoutineContext } from '../../context/RoutineContext';
import { RoutineType } from '../../context/hooks/useRoutine';
import { TaxTasksContext } from '../../context/TaxTasksContext';
import { TaxTasksType } from '../../context/hooks/useTaxTasks';
import { UploadContext } from '../../context/UploadContext';
import { DownloadContext } from '../../context/DownloadContext';
import MultipleFileUpload from '../../components/MultipleFileUpload/MultipleFileUpload';
import { format, subMonths, startOfMonth, endOfMonth, parseISO } from 'date-fns';

const CustomerRoutine = () => {
    const { objUserTk } = useContext(AuthContext);
    const { getAllRoutinePaged, listRoutine, pageCount } = useContext(RoutineContext);
    const { getAllTaxTaskPaged, listTaxTasks, taskPageCount } = useContext(TaxTasksContext);
    const { handleUpload } = useContext(UploadContext);
    const { handleDownloadAll, handleDownloadByTaxTasksId } = useContext(DownloadContext);

    const [selectedTaskId, setSelectedTaskId] = useState(0);
    const [selectedRoutineId, setSelectedRoutineId] = useState(0);
    const [files, setFiles] = useState<File[]>([]);
    const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataSearch, setDataSearch] = useState({
        customerName: '',
        routineDescription: '',
        startDate: '',
        endDate: '',
        status: 0,
        tag: '',
    });
    const defaultStartDate = format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd');
    const defaultEndDate = format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd');

    useEffect(() => {
        setLoading(true);
        getAllRoutinePaged(1, 0, 0, objUserTk.UserId)
            .then(data => {
                setLoading(false);
            })
            .catch(error => {
                console.error("Erro ao buscar rotinas:", error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (modalOpen && selectedRoutineId) {
            setLoading(true);
            getAllTaxTaskPaged(
                1,
                selectedRoutineId,
                objUserTk.OfficeId,
                dataSearch.status,
                dataSearch.customerName,
                dataSearch.routineDescription,
                dataSearch.startDate || defaultStartDate,
                dataSearch.endDate || defaultEndDate,
                dataSearch.tag
            )
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
        }
    }, [modalOpen, selectedRoutineId]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDataSearch({ ...dataSearch, [e.target.name]: e.target.value });
    };

    const handleSearchSubmit = () => {
        setLoading(true);
        getAllTaxTaskPaged(
            1,
            selectedRoutineId || 0,
            objUserTk.OfficeId,
            dataSearch.status,
            dataSearch.customerName,
            dataSearch.routineDescription,
            dataSearch.startDate,
            dataSearch.endDate,
            dataSearch.tag
        )
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    const handleClearFilters = () => {
        setDataSearch({
            customerName: '',
            routineDescription: '',
            startDate: '',
            endDate: '',
            status: 0,
            tag: '',
        });
        setLoading(true);
        getAllTaxTaskPaged(1, selectedRoutineId || 0, objUserTk.OfficeId, dataSearch.status, dataSearch.customerName, dataSearch.routineDescription, dataSearch.startDate, dataSearch.endDate, dataSearch.tag)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    const handleDownloadFile = async (officeId: number, taxTasksId: number) => {
        await handleDownloadByTaxTasksId(officeId, taxTasksId);
    };

    const onChangePaginationCommon = async (e: any, value: any) => {
        await getAllTaxTaskPaged(value, 0, objUserTk.OfficeId);
    };

    const formatDateTime = (dateString: string) => {
        if (!dateString) return 'Data não informada';
        const date = parseISO(dateString);
        return dateString.includes('T')
            ? format(date, 'dd/MM/yyyy HH:mm')
            : format(date, 'dd/MM/yyyy');
    };

    const formatTaskStatus = (status: number) => {
        switch (status) {
            case 1:
                return { label: 'Em Aberto', color: '#007bff' };
            case 2:
                return { label: 'Concluído', color: '#28a745' };
            case 3:
                return { label: 'Atrasado', color: '#ffc107' };
            case 4:
                return { label: 'Rejeitado', color: '#dc3545' };
            case 5:
                return { label: 'Aprovado', color: '#ffcc00' }
            default:
                return { label: 'Desconhecido', color: '#6c757d' };
        }
    };

    return (
        <>
            <LoadingSpinner />
            <Header />
            <AdminNavbar
                brandText={"Gerenciar Rotinas"}
                search={false}
                titleSearch={""}
            />

            <Container className="mt-5">
                <Row className="mt-4">
                    <Col>
                        <Table hover responsive className="table-striped custom-table">
                            <thead>
                                <tr>
                                    <th>Cliente</th>
                                    <th>Rotina</th>
                                    <th>Data final</th>
                                    <th>Frequencia</th>
                                    <th>Número de cobranças</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listRoutine.map((routine: RoutineType, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td>{routine.customer.razaoSocial}</td>
                                            <td>{routine.description}</td>
                                            <td>{formatDateTime(routine.finalDate)}</td>
                                            <td>{routine.frequency}</td>
                                            <td>{routine.numberOfCharges}</td>
                                            <td>
                                                <Button
                                                    className="action-btn"
                                                    onClick={async () => {
                                                        await setSelectedRoutineId(routine.id);
                                                        // getAllTaxTaskPaged(1, selectedRoutineId || 0, objUserTk.OfficeId, dataSearch.status, dataSearch.customerName, dataSearch.routineDescription, defaultStartDate, defaultEndDate, dataSearch.tag)
                                                        setModalOpen(true);
                                                    }}
                                                >
                                                    <i className="fa-solid fa-eye"></i>
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-end mt-3">
                            {pageCount && (
                                <div className="d-flex justify-content-center mt-4">
                                    <PaginationMaterial
                                        onChange={(e: any, value: any) => onChangePaginationCommon(e, value)}
                                        count={pageCount}
                                    />
                                </div>
                            )}
                            {listRoutine.length === 0 && (
                                <div style={{ color: "red" }}>NÃO FOI ENCONTRADO NENHUM REGISTRO</div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>

            <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} backdrop="static" className="custom-modal-xl">
                <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Informações da rotina</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="startDate">Data Inicial</Label>
                                <Input className="form-control-alternative" type="date" name="startDate" value={dataSearch.startDate} onChange={handleSearchChange} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="endDate">Data Final</Label>
                                <Input className="form-control-alternative" type="date" name="endDate" value={dataSearch.endDate} onChange={handleSearchChange} />
                            </FormGroup>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Button className="search-btn" onClick={handleSearchSubmit}>Buscar</Button>
                            <Button className="clear-btn btn-danger" onClick={handleClearFilters}>Limpar</Button>
                        </Col>
                    </Row>
                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        <Table hover responsive className="table-striped custom-table">
                            <thead>
                                <tr>
                                    <th>Cliente</th>
                                    {/* <th>CNPJ</th> */}
                                    <th>Rotina</th>
                                    <th>Enviar em</th>
                                    <th>Data de submissão</th>
                                    <th>Status</th>
                                    <th>Download</th>
                                    <th>Upload</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listTaxTasks.map((taxTasks: TaxTasksType, index: number) => {
                                    const formattedStatus = formatTaskStatus(Number(taxTasks.status));
                                    return (
                                        <tr key={index}>
                                            <td>{taxTasks.routine.customer.razaoSocial}</td>
                                            {/* <td>{taxTasks.routine.customer.cnpj}</td> */}
                                            <td>{taxTasks.routine.description.length > 20 ? taxTasks.routine.description.substring(0, 20) + '...' : taxTasks.routine.description}</td>
                                            <td>{formatDateTime(taxTasks.shippingDate)}</td>
                                            <td>{taxTasks.submissionDate ? formatDateTime(taxTasks.submissionDate) : 'Não enviada'}</td>
                                            <td>
                                                <span
                                                    className="status-capsule"
                                                    style={{
                                                        backgroundColor: formattedStatus.color,
                                                        padding: '5px 10px',
                                                        borderRadius: '12px',
                                                        color: '#fff'
                                                    }}
                                                >
                                                    {formattedStatus.label}
                                                </span>
                                            </td>
                                            {/* <td>
                                                <Button
                                                    className="action-btn"
                                                    onClick={() => handleDownloadFile(objUserTk.OfficeId, taxTasks.id)}
                                                    disabled={!taxTasks.hasDocument}
                                                >
                                                    <i className="fa-solid fa-download"></i> Baixar Arquivo
                                                </Button>
                                            </td> */}
                                            <td>
                                                {taxTasks.hasDocument || Number(taxTasks.status) !== 2 ? (
                                                    <Button
                                                        className="action-btn"
                                                        onClick={() => handleDownloadFile(objUserTk.OfficeId, taxTasks.id)}
                                                        disabled={!taxTasks.hasDocument}
                                                    >
                                                        <i className="fa-solid fa-download"></i> Baixar
                                                    </Button>
                                                ) : (
                                                    <span className="no-document">Sem documento</span>
                                                )}
                                            </td>
                                            <td>
                                                <Button className="action-btn" onClick={() => {
                                                    setSelectedTaskId(taxTasks.id);
                                                    setIsFileUploadModalOpen(true);
                                                }}>
                                                    <i className="fa-solid fa-upload"></i> Upload
                                                </Button>
                                                <Button
                                                    className="action-btn"
                                                    onClick={async () => {
                                                        if (taxTasks.id) {
                                                            const uploadResponse = await handleUpload({
                                                                taxTaskId: Number(taxTasks.id),
                                                                officeId: objUserTk.OfficeId,
                                                                fileName: "",
                                                                files: [],
                                                            });
                                                            if (uploadResponse === null) {
                                                                await getAllTaxTaskPaged(1, selectedRoutineId || 0, objUserTk.OfficeId, dataSearch.status, dataSearch.customerName, dataSearch.routineDescription, defaultStartDate, defaultEndDate, dataSearch.tag)
                                                            }
                                                        } else {
                                                            alert("Selecione uma tarefa.");
                                                        }
                                                    }}
                                                >
                                                    <i className="fa-solid fa-upload"></i> Nada a declarar
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    )}
                    <div className="d-flex justify-content-end mt-3">
                        {taskPageCount && (
                            <div className="d-flex justify-content-center mt-4">
                                <PaginationMaterial
                                    onChange={(e: any, value: any) => onChangePaginationCommon(e, value)}
                                    count={taskPageCount}
                                />
                            </div>
                        )}
                        {listTaxTasks.length === 0 && (
                            <div style={{ color: "red" }}>NÃO FOI ENCONTRADO NENHUM REGISTRO</div>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{ backgroundColor: "#585858", color: "#FFF" }}
                        onClick={() => handleDownloadAll(objUserTk.OfficeId, selectedRoutineId)}
                    >
                        Baixar Todos os Arquivos
                    </Button>
                </ModalFooter>
            </Modal >

            <Modal isOpen={isFileUploadModalOpen} toggle={() => setIsFileUploadModalOpen(!isFileUploadModalOpen)} className="modal-lg">
                <ModalHeader toggle={() => setIsFileUploadModalOpen(!isFileUploadModalOpen)}>
                    Upload de Arquivo
                </ModalHeader>
                <ModalBody>
                    <MultipleFileUpload onFilesSelected={setFiles} />
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{ backgroundColor: "#585858", color: "#FFF" }}
                        onClick={async () => {
                            if (files.length > 0 && selectedTaskId) {
                                const uploadResponse = await handleUpload({
                                    taxTaskId: selectedTaskId,
                                    officeId: objUserTk.OfficeId,
                                    fileName: files.map(file => file.name).join(", "),
                                    files: files
                                });

                                if (uploadResponse === null) {
                                    setIsFileUploadModalOpen(false);
                                    await getAllTaxTaskPaged(1, selectedRoutineId, objUserTk.OfficeId);
                                }
                            } else {
                                alert("Selecione arquivos para upload.");
                            }
                        }}
                    >
                        Enviar Arquivos
                    </Button>
                    <Button color="secondary" onClick={() => setIsFileUploadModalOpen(false)}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default CustomerRoutine;