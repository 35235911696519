import React, { useContext, useEffect } from 'react';
import { Container } from "reactstrap";
import Header from "../components/Headers/Header";
import AdminNavbar from "../components/Navbars/AdminNavbar/AdminNavbar";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import { AuthContext } from '../context/AuthContext'; 
import Dashboard from '../components/Dashboard/Dashboard';
import useUser from '../context/hooks/useUser';
import { set } from 'lodash';

const Home = () => {
  const { handleGetByOfficeIdAndUserId, user } = useUser();
  const { objUserTk } = useContext(AuthContext); 

  useEffect(() => {
    handleGetByOfficeIdAndUserId(objUserTk.OfficeId, objUserTk.UserId);
  }, []);

  return (
    <>
      <LoadingSpinner />
      <Header />
      <AdminNavbar 
        brandText={"Dashboard"}
        search={false}
        titleSearch={""}
      />

      <Container className="mt-5">
        <Dashboard />
      </Container>
    </>
  );
};

export default Home;
