import { ReactNode, createContext } from "react";
import useCustomer, { CustomerType, CreateCustomerType, UpdateCustomerType } from "./hooks/useCustomer";

interface CustomerContextProviderProps {
    children: ReactNode;
}

export interface CustomerContextData {
    createCustomer: (createCustomerData: CreateCustomerType) => Promise<CreateCustomerType | null>;
    importCustomers: (file: File, officeId: number) => Promise<any>;
    getAllCustomersPaged: (
        pageNumber: number,
        razaoSocial?: string,
        officeId?: number
    ) => Promise<CustomerType[] | null>;
    updateCustomer: (updateCustomerData: UpdateCustomerType) => Promise<UpdateCustomerType | null>;
    deleteCustomer: (customerId: number) => Promise<CustomerType | null>;
    toggleCustomerStatus: (customerId: number) => Promise<void>;
    setCustomer: any;
    customer: CustomerType;
    listCustomer: CustomerType[];
    initialListCustomer: CustomerType[];
    setListCustomer: any;
    setPageCount: any;
    pageCount: number;
}

const initialState: CustomerContextData = {
    createCustomer: async () => null,
    importCustomers: async () => null,
    getAllCustomersPaged: async (
        pageNumber: number,
        razaoSocial?: string,
        officeId?: number
    ) => {
        return Promise.resolve(null);
    },
    updateCustomer: async () => null,
    deleteCustomer: async () => null,
    setCustomer: () => { },
    toggleCustomerStatus: async () => { },
    customer: {
        razaoSocial: "",
        nomeFantasia: "",
        isActive: false,
        document: "",
        documentType: 0,
        telefone: "",
        emailAcesso: "",
        resend: false,
        workerDownloadFilePath: "",
        id: 0,
        user: null,
        office: {
            id: 0,
        },
    },
    listCustomer: [],
    initialListCustomer: [],
    setListCustomer: () => {},
    setPageCount: () => {},
    pageCount: 0,
};

export const CustomerContext =
    createContext<CustomerContextData>(initialState);

export default function CustomerContextProvider({
    children,
}: CustomerContextProviderProps) {
    const {
        createCustomer,
        importCustomers,
        getAllCustomersPaged,
        toggleCustomerStatus,
        updateCustomer,
        deleteCustomer,
        setCustomer,
        customer,
        listCustomer,
        initialListCustomer,
        setListCustomer,
        pageCount,
        setPageCount,
    } = useCustomer();

    return (
        <CustomerContext.Provider
            value={{
                createCustomer,
                importCustomers,
                getAllCustomersPaged,
                toggleCustomerStatus,
                updateCustomer,
                deleteCustomer,
                setCustomer,
                customer,
                listCustomer,
                initialListCustomer,
                setListCustomer,
                pageCount,
                setPageCount,
            }}
        >
            {children}
        </CustomerContext.Provider>
    );
}
