import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";

export interface CustomerType {
    razaoSocial: string;
    nomeFantasia: string;
    isActive: boolean;
    document: string;
    documentType: number;
    telefone: string;
    emailAcesso: string;
    resend?: boolean;
    photo?: string;
    workerDownloadFilePath?: string;
    id: number;
    user: null;
    office: {
        id: number;
    };
}

export interface CreateCustomerType {
    razaoSocial: string;
    nomeFantasia: string;
    document: string;
    documentType: number;
    telefone: string;
    emailAcesso: string;
    resend?: boolean;
    workerDownloadFilePath?: string;
    photo: string;
    officeId: number;
}

export interface UpdateCustomerType {
    id: number;
    razaoSocial: string;
    nomeFantasia: string;
    document: string;
    documentType: number;
    telefone: string;
    emailAcesso: string;
    resend?: boolean;
    workerDownloadFilePath?: string;
    photo: string;
}

export default function useCustomer() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const { handleLogout, objUserTk } = useContext(AuthContext);
    const [listCustomer, setListCustomer] = useState<CustomerType[]>([]);
    const [pageCount, setPageCount] = useState<number>(0);
    const [initialListCustomer, setInitialListCustomer] = useState<CustomerType[]>([]);
    const [customer, setCustomer] = useState<CustomerType>({
        razaoSocial: "",
        nomeFantasia: "",
        isActive: false,
        document: "",
        documentType: 0,
        telefone: "",
        emailAcesso: "",
        resend: false,
        workerDownloadFilePath: "",
        id: 0,
        user: null,
        office: {
            id: 0,
        },
    });

    function createCustomer(createCustomerType: CreateCustomerType): Promise<CreateCustomerType | null> {
        setLoading(true);
        return api
            .post(`/Customer/create`, createCustomerType)
            .then((response) => {
                const createdCustomer: CreateCustomerType = response.data;
                showToast("success", "Cliente criado com sucesso.");
                setLoading(false);
                return createdCustomer;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao criar cliente. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao criar cliente:", error);
                return null;
            });
    }

    function importCustomers(file: File, officeId: number): Promise<string | null> {
        setLoading(true);
    
        const formData = new FormData();
        formData.append("file", file);
    
        return api
            .post(`/Customer/import?officeId=${officeId}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                showToast("success", `Clientes importados com sucesso. Total: ${response.data.count}`);
                setLoading(false);
                return response.data.message;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao importar clientes. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao importar clientes:", error);
                return null;
            });
    }    

    function getAllCustomersPaged(
        pageNumber: number,
        Name?: string,
        officeId?: number
    ): Promise<CustomerType[] | null> {
        setLoading(true);
        let url = `/Customer/getAllCustomerPaged?pageNumber=${pageNumber}&pageSize=9`;

        if (Name) {
            url += `&Name=${Name}`;
        }

        if (officeId) {
            url += `&OfficeId=${officeId}`;
        }

        return api
            .get(url)
            .then((response) => {
                setListCustomer(response.data.result);
                setInitialListCustomer(response.data.result);
                setPageCount(response.data.pageCount);
                setLoading(false);
                return response.data.result;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao carregar clientes. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao carregar clientes:", error);
                return null;
            });
    }

    function updateCustomer(updateCustomerData: UpdateCustomerType): Promise<UpdateCustomerType | null> {
        setLoading(true);
        return api
            .put(`/Customer/${updateCustomerData.id}`, updateCustomerData)
            .then((response) => {
                showToast("success", "Cliente atualizado com sucesso.");
                setLoading(false);
                return response.data;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao atualizar cliente. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao atualizar cliente:", error);
                return null;
            });
    }

    function deleteCustomer(customerId: number): Promise<CustomerType | null> {
        setLoading(true);
        return api
            .delete(`/Customer/${customerId}`)
            .then(() => {
                setCustomer({
                    razaoSocial: "",
                    nomeFantasia: "",
                    isActive: false,
                    document: "",
                    documentType: 0,
                    telefone: "",
                    emailAcesso: "",
                    resend: false,
                    workerDownloadFilePath: "",
                    id: 0,
                    user: null,
                    office: {
                        id: 0,
                    },
                });
                showToast("success", "Cliente deletado com sucesso.");
                setLoading(false);
                return null;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao deletar cliente. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao deletar cliente:", error);
                return null;
            });
    }

    function handleGetByUserId(id: number): Promise<CustomerType | null> {
        setLoading(true);
        return api
            .get(`/Customer/getbyuserid/${id}`)
            .then((response) => {
                const customer: CustomerType = response.data;
                setCustomer(customer);
                setLoading(false);
                return customer;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao obter informações do cliente. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao obter informações do cliente:", error);
                return null;
            });
    }

    function toggleCustomerStatus(customerId: number): Promise<void> {
        setLoading(true);
        return api
            .patch(`/Customer/${customerId}/toggle-status`)
            .then(() => {
                showToast("success", "Status do cliente atualizado com sucesso.");
                setLoading(false);
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao atualizar o status do cliente. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao atualizar o status do cliente:", error);
            });
    }
    

    return {
        createCustomer,
        importCustomers,
        getAllCustomersPaged,
        updateCustomer,
        deleteCustomer,
        handleGetByUserId,
        setCustomer,
        customer,
        setListCustomer,
        listCustomer,
        initialListCustomer,
        setPageCount,
        pageCount,
        toggleCustomerStatus,
    };
}
